import React from 'react';
import PropTypes from 'prop-types';

export const IconMenu = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 18"
  >
    <g transform="translate(5974 15384)">
      <rect width="25" height="2" transform="translate(-5974 -15384)" />
      <rect width="25" height="2" transform="translate(-5974 -15376)" />
      <rect width="25" height="2" transform="translate(-5974 -15368)" />
    </g>
  </svg>
);

IconMenu.propTypes = {
  className: PropTypes.string,
};

IconMenu.defaultProps = {
  className: '',
};
