import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { IconArrow } from './icons/arrow';
import { IconInstagram } from './icons/instagram';
import { IconPinterest } from './icons/pinterest';

export const Footer = () => {
  const [hasSignedUp, setHasSignedUp] = useState();

  useEffect(() => {
    setHasSignedUp(localStorage.getItem('emailSignup'));
  }, []);

  return (
    <footer className="footer" role="contentinfo">
      <div className="container">
        <div>
          <AniLink fade to="/privacy-policy" className="footer__link">
            Privacy
          </AniLink>
          <p className="footer__title">
            <span>2020&copy;Neue</span>
            <span className="tan">Amsterdam</span>
          </p>
          <p className="footer__tagline">You Had Me At Desire</p>
        </div>

        <AnchorLink className="footer__btt" href="#main" offset="84">
          <span className="visuallyhidden">back to top</span>
          <IconArrow />
        </AnchorLink>

        <div>
          <form
            name="footerSignup"
            className="footer__form"
            method="POST"
            action="?signup_success=true"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="footerSignup" />
            <label className="footer__label" htmlFor="email-signup">
              Neue<span className="tan">News:</span>
            </label>
            <input
              type="email"
              className="footer__input"
              id="email-signup"
              name="email"
              placeholder={hasSignedUp ? 'Submitted' : 'Sign Up'}
            />
            <button
              className="footer__submit"
              type="submit"
              aria-label="email signup"
              onClick={() => localStorage.setItem('emailSignup', true)}
            >
              <span className="visuallyhidden">submit</span>
              <IconArrow />
            </button>
          </form>
          <div className="footer__social">
            <a href="https://www.instagram.com/neueamsterdam/">
              <span className="visuallyhidden">
                Neue Amsterdam on Instagram
              </span>
              <IconInstagram />
            </a>
            <a href="https://www.pinterest.com/neueamsterdam/">
              <span className="visuallyhidden">
                Neue Amsterdam on Pinterest
              </span>
              <IconPinterest />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
