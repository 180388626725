import React from 'react';
import PropTypes from 'prop-types';

export const IconPinterest = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.11 17.81"
  >
    <path
      fill="#9b846d"
      d="M3.09,17.81A8.83,8.83,0,0,1,3,14.3c.37-1.88.86-3.73,1.27-5.61a2.46,2.46,0,0,0,0-1,3.28,3.28,0,0,1,.29-2.75A2.65,2.65,0,0,1,5.66,4a1.32,1.32,0,0,1,1.75.66h0a1.41,1.41,0,0,1,.11.73A16.28,16.28,0,0,1,7,7.7c-.1.37-.25.73-.34,1.1a1.63,1.63,0,0,0,.94,2.12h0a1.59,1.59,0,0,0,1.23,0,2.85,2.85,0,0,0,1.63-1.26,6.94,6.94,0,0,0,1-4.55A3.45,3.45,0,0,0,9.33,2.15,5.51,5.51,0,0,0,3.25,3.43,4.42,4.42,0,0,0,2.37,8a.94.94,0,0,0,.14.26c.55.49.43,1,.22,1.64-.13.4-.35.5-.72.33A3.45,3.45,0,0,1,.11,7.53C-.6,4,2.15.9,5.46.22a8.4,8.4,0,0,1,5.1.41A5.75,5.75,0,0,1,14.1,6.28,7.88,7.88,0,0,1,13,10.18a5.1,5.1,0,0,1-5.16,2.57A4.48,4.48,0,0,1,6.32,12c-.13-.07-.2-.25-.38-.37-.59,2.2-1.12,4.39-2.86,6.17"
    />
  </svg>
);

IconPinterest.propTypes = {
  className: PropTypes.string,
};

IconPinterest.defaultProps = {
  className: '',
};
